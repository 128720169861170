@import url("https://use.typekit.net/cec5cnx.css");
@font-face {
  font-family: "Wild Spirit";
  src: url("../fonts/WildSpirit.woff2") format("woff2"), url("../fonts/WildSpirit.woff") format("woff"); }

.slick-dots li button:before {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-image: url("../img/dot-border.png");
  background-repeat: no-repeat;
  content: "";
  opacity: 1; }

.slick-dots li.slick-active button:before {
  background-image: url("../img/dot-full.png");
  background-repeat: no-repeat; }

.slick-list {
  margin: 0 -10px; }

.slick-slide {
  margin: 0 10px; }

.slick-track {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-bottom: 30px;
  cursor: pointer; }

/**********************
Global Styles
**********************/
*,
*:after,
*:before {
  box-sizing: border-box; }

/** Fixing bootstrap columns for safari **/
.row:after,
.row:before {
  display: inline-block !important; }

body {
  color: #757575;
  font-size: 17px;
  line-height: 1.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: brandon-grotesque, sans-serif;
  letter-spacing: 0;
  overflow-x: hidden; }

button {
  background-color: transparent;
  border: 0; }
  button:hover {
    cursor: pointer; }
  button:focus {
    outline: 0; }

a,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0; }

section {
  padding: 80px 0; }

.container {
  max-width: 1140px; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto; }

.embed-container embed,
.embed-container iframe,
.embed-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.section-title {
  text-transform: uppercase;
  font-size: 42px;
  color: #1D222C;
  font-weight: 900; }

.sub-heading {
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 1px;
  display: inline-block;
  text-transform: uppercase;
  color: #1D222C;
  font-weight: 700; }

strike {
  color: rgba(136, 136, 136, 0.7); }

.img-container {
  text-align: center;
  line-height: 1; }

.section-header {
  margin-bottom: 16px; }

.sale-text {
  font-weight: 700;
  font-size: 50px;
  line-height: 1;
  margin: 20px 0 30px;
  color: #1D222C; }
  .sale-text span {
    text-decoration: line-through;
    color: #c9c9c9;
    font-weight: 400; }

.bundle-price {
  font-size: 24px; }

.modal {
  background-color: rgba(0, 0, 0, 0.8); }

/* Buttons */
.btn-prime,
.btn-prime:link,
.btn-prime:visited {
  padding: 10px 25px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  display: inline-block;
  text-align: center;
  font-size: 17px;
  vertical-align: middle;
  box-shadow: none !important;
  outline: 0;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px; }

.btn-prime:hover {
  opacity: 0.9; }

.btn-prime-dark,
.btn-prime-dark:focus,
.btn-prime-dark:hover {
  border: 2px solid #EAAE27;
  background-color: #EAAE27;
  color: #fff; }

.btn-prime-border,
.btn-prime-border:focus,
.btn-prime-border:hover {
  background-color: #EAAE27;
  border: 2px solid #EAAE27;
  color: #fff; }

.flex-container {
  -webkit-box-align: center;
  align-items: center; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto; }

.embed-container embed,
.embed-container iframe,
.embed-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.link-color {
  color: #EAAE27;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 700; }
  .link-color:hover {
    color: #EAAE27;
    opacity: 0.8; }

@media only screen and (max-width: 767px) {
  section {
    padding: 60px 0; }
  .sub-heading {
    font-size: 17px; } }

@media only screen and (max-width: 600px) {
  .section-title {
    font-size: 28px; } }

@media only screen and (max-width: 375px) {
  .section-title {
    font-size: 24px; } }

.text-style {
  font-family: "Wild Spirit";
  color: #EAAE27;
  font-size: 100px;
  line-height: 1; }

.section-header {
  margin-bottom: 50px; }
  .section-header .img-container {
    margin-bottom: 10px; }

.lines {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center; }
  .lines .img-container {
    margin: 0; }
  .lines .sub-heading {
    padding: 0 20px; }

/**********************
Navigation
**********************/
header {
  background-color: #fff;
  box-shadow: none;
  height: 80px; }
  header .container {
    height: 100%; }
  header .link-centered {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
  header .svg-inline--fa {
    font-size: 20px;
    margin-right: 5px; }
  header #main-nav {
    padding: 10px 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 100%; }
    header #main-nav ul.menu-list {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none; }
  header .navbar-dark .navbar-nav .nav-link:hover {
    background-color: transparent;
    color: #EAAE27; }
  header .buy-now {
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    text-transform: uppercase;
    margin-left: 10px; }
    header .buy-now:hover {
      background-color: #EAAE27;
      border: 2px solid #EAAE27;
      color: #fff !important; }

.nav-link,
.nav-link:active,
.nav-link:focus,
.nav-link:hover,
.nav-link:visited {
  color: #fff;
  font-size: 13px;
  outline: 0;
  font-weight: 700;
  letter-spacing: 0.05em;
  padding: 10px 15px !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase; }
  .nav-link:hover,
  .nav-link:active:hover,
  .nav-link:focus:hover,
  .nav-link:hover:hover,
  .nav-link:visited:hover {
    color: #EAAE27; }

.navbar-dark .navbar-brand {
  font-weight: 700;
  color: #EAAE27;
  height: 100%;
  padding: 0; }

.navbar-brand {
  padding: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center; }

.cta-btn {
  background-color: #EAAE27; }

.cta-btn .nav-link {
  color: #fff !important; }

@media only screen and (max-width: 991px) {
  header {
    height: 70px; } }

/* Mobile Menu */
header {
  background-color: #1D222C;
  position: relative;
  box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2); }

@media screen and (min-width: 992px) {
  .mobile-menu {
    display: none; } }

@media screen and (max-width: 991px) {
  #main-nav {
    display: none !important; }
  .mobile-nav .btn-prime.btn-prime-dark {
    color: #fff;
    font-size: 26px;
    width: 100%;
    text-transform: uppercase;
    text-transform: inherit; }
  input#burger {
    display: none; }
  .mobile-menu .navbar-brand {
    font-weight: 700;
    color: #EAAE27;
    height: 45px; }
  .mobile-menu input:not(:checked) .mobile-links {
    display: none; }
  .mobile-menu input + label {
    position: fixed;
    top: 25px;
    right: 30px;
    height: 20px;
    width: 34px;
    z-index: 5;
    padding: 0;
    margin-bottom: 0; }
  .mobile-menu input + label span {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 60%;
    margin-top: -1px;
    left: 0;
    display: block;
    background: #EAAE27;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .mobile-menu input + label span:first-child {
    top: 4px; }
  .mobile-menu input + label span:last-child {
    top: 20px; }
  .mobile-menu label:hover {
    cursor: pointer; }
  .mobile-menu input:checked + label span {
    opacity: 0;
    top: 50%; }
  .mobile-menu input:checked + label span:first-child {
    opacity: 1;
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); }
  .mobile-menu input:checked + label span:last-child {
    opacity: 1;
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); }
  .mobile-menu input ~ nav {
    background: #1D222C;
    box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 3;
    overflow: hidden; }
  .mobile-menu nav {
    position: relative; }
  .mobile-menu input ~ nav > ul {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
    margin-bottom: 0;
    width: 80%; }
  .mobile-menu input ~ nav > ul > li {
    display: none; }
  .mobile-menu input ~ nav > ul > li > a {
    color: #fff;
    font-weight: 700;
    display: block;
    padding: 12px;
    font-size: 25px;
    text-transform: uppercase; }
  .mobile-menu input:checked ~ nav {
    height: 100%;
    -webkit-transition-delay: 0s;
    transition-delay: 0s; }
  .mobile-links {
    list-style: none; }
  .mobile-menu input:checked ~ nav > ul > li {
    display: initial; }
  .mobile-menu .navbar-brand {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3000;
    height: 100%;
    padding: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
    .mobile-menu .navbar-brand img {
      height: 45px; }
  .mobile-menu input + label {
    right: 20px; }
  .mobile-menu .navbar-brand {
    left: 15px; } }

@media screen and (max-width: 575px) {
  .mobile-menu input ~ nav > ul > li > a,
  .mobile-nav .btn-prime.btn-prime-dark {
    font-size: 17px; } }

/******************************
Hero
******************************/
.hero {
  position: relative;
  background-image: url("../img/hero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #F2F2F2;
  margin-top: 80px;
  color: #fff;
  text-align: center;
  height: 650px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center; }
  .hero::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1D222C;
    opacity: 0.2; }
  .hero .container {
    position: relative;
    z-index: 1; }
  .hero .section-header {
    margin-bottom: 30px; }
    .hero .section-header .section-title {
      font-size: 85px;
      line-height: 1;
      margin-bottom: 0;
      color: #fff; }
    .hero .section-header .text-style {
      margin-bottom: -10px; }
    .hero .section-header .sub-heading {
      color: #fff;
      font-size: 30px;
      margin: 0; }
      .hero .section-header .sub-heading span {
        display: block; }

@media only screen and (min-width: 2000px) {
  .hero {
    height: 1000px; } }

@media only screen and (max-width: 991px) {
  .hero {
    margin-top: 70px; }
  .hero::after {
    opacity: 0.3; }
  .hero .section-header .section-title {
    font-size: 70px; }
  .hero .section-header .sub-heading {
    font-size: 24px; } }

@media only screen and (min-width: 768px) {
  .hero .mobile-img {
    display: none; } }

@media only screen and (max-width: 767px) {
  .hero {
    padding-top: 0;
    text-align: center;
    background-image: none;
    background-color: #fff;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: auto; }
    .hero .img-container {
      margin-bottom: 50px; }
    .hero .lines .img-container {
      margin-bottom: 0; }
  .hero .section-header .text-style {
    margin-top: -10px; }
  .hero::after {
    display: none; }
  .hero .section-header .section-title {
    color: #1D222C;
    font-size: 42px; }
  .hero .section-header .sub-heading {
    font-size: 20px;
    color: #1D222C; }
  .hero .section-header .sub-heading:nth-child(2) {
    margin-top: 0; } }

@media only screen and (max-width: 600px) {
  .hero .section-header .section-title {
    font-size: 30px;
    margin-bottom: 10px; }
  .hero .section-header .text-style {
    margin-top: 0;
    font-size: 76px;
    margin-bottom: 0; }
  .hero .section-header .sub-heading {
    font-size: 20px; }
  .hero .section-header .text-img {
    max-width: 150px; } }

@media only screen and (max-width: 450px) {
  .hero .cta-container .btn-prime {
    display: block; }
  .sale-text {
    font-size: 36px;
    margin: 16px 0; } }

.benefits {
  background-color: #F2F2F2;
  padding: 50px 0; }
  .benefits .grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 40px; }
  .benefits .box-item {
    text-align: center; }
    .benefits .box-item .title {
      text-transform: uppercase;
      font-weight: 700;
      color: #1D222C; }
      .benefits .box-item .title span {
        display: block; }
    .benefits .box-item .img-container {
      margin-bottom: 5px; }

@media only screen and (max-width: 991px) {
  .benefits .grid-row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    margin: 0 auto; }
  .benefits .box-item {
    width: 33%; }
    .benefits .box-item:not(:last-child) {
      margin-bottom: 20px; } }

@media only screen and (max-width: 600px) {
  .benefits .box-item {
    width: 50%; } }

.video {
  text-align: center; }
  .video .section-header {
    margin-bottom: 0; }
    .video .section-header .section-title {
      margin-bottom: 0; }
    .video .section-header .sub-heading {
      margin-bottom: 0; }
    .video .section-header .img-container {
      margin-bottom: 10px; }
  .video .cta-container {
    margin-top: 40px; }
  .video .main-video .video-container {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center; }
  .video .main-video .img-container {
    position: relative;
    margin: 40px 0; }
    .video .main-video .img-container::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #1D222C;
      opacity: 0.1; }
  .video .main-video .play-btn {
    position: absolute;
    max-width: 100px;
    z-index: 1; }

.sessions {
  background-color: #F2F2F2;
  text-align: center; }
  .sessions .grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px; }
  .sessions h4 {
    text-transform: uppercase;
    font-weight: 700; }
  .sessions .flip-container {
    position: relative;
    width: 100%;
    padding: 0 5px 10px;
    cursor: pointer;
    color: #fff; }
  .sessions .flip-container:hover .front {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s 0.1s, visibility 0.4s;
    transition: opacity 0.3s 0.1s, visibility 0.4s; }
  .sessions .flipper {
    position: relative;
    width: 100%;
    height: 100%; }
  .sessions .back,
  .sessions .front {
    -webkit-transition: opacity 0.3s 0.1s, visibility;
    transition: opacity 0.3s 0.1s, visibility;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: #fff; }
  .sessions .front {
    z-index: 2;
    border: 10px solid #fff; }
  .sessions .back {
    position: relative; }
    .sessions .back .text {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      padding: 0 30px;
      line-height: 1.1;
      font-size: 16px; }
  .sessions .back-inner {
    height: 100%;
    display: -webkit-box;
    display: flex;
    padding: 10px; }
  .sessions .back-content {
    display: inline-block;
    margin: auto; }
  .sessions .cta-container {
    margin-top: 40px; }

@media only screen and (max-width: 1199px) {
  .sessions .grid-row {
    grid-template-columns: 1fr 1fr 1fr; } }

@media only screen and (max-width: 768px) {
  .sessions .grid-row {
    grid-template-columns: 1fr 1fr; } }

@media only screen and (max-width: 600px) {
  .sessions .grid-row {
    grid-template-columns: 1fr;
    gap: 50px; }
  .sessions .flip-container:hover .front {
    visibility: visible;
    opacity: 1; }
  .sessions .back,
  .sessions .back .text,
  .sessions .front {
    position: relative; }
  .sessions .back .text {
    padding: 30px 20px;
    color: #1D222C;
    font-size: 17px;
    line-height: 1.5; }
  .sessions .back-inner {
    padding: 0; }
  .sessions .back-content .img-container {
    display: none; }
  .sessions .front {
    border-bottom: 0; } }

/**********************
Purchase
**********************/
.purchase {
  position: relative;
  background-image: url("../img/purchase-bg.jpg");
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center; }
  .purchase::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1D222C;
    opacity: 0.3; }
  .purchase .container {
    position: relative;
    z-index: 1; }
  .purchase .section-title {
    color: #fff; }
  .purchase .sub-heading {
    color: #fff; }
  .purchase .grid-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px; }
    .purchase .grid-row .item {
      background-color: #fff;
      padding: 40px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column; }
      .purchase .grid-row .item .img-container {
        margin: 0 0 20px; }
      .purchase .grid-row .item .price {
        font-weight: 900;
        color: #1D222C;
        margin: 16px 0 30px;
        font-size: 30px; }
        .purchase .grid-row .item .price span {
          text-decoration: line-through;
          opacity: 0.3; }
      .purchase .grid-row .item .lines {
        max-width: 400px;
        margin: 0 auto; }
        .purchase .grid-row .item .lines .sub-heading {
          font-weight: 900;
          color: #1D222C; }
      .purchase .grid-row .item .text-style {
        margin: 30px 0; }
      .purchase .grid-row .item .des {
        padding: 15px 0;
        width: 100%; }
        .purchase .grid-row .item .des .title {
          font-weight: 900;
          color: #1D222C;
          font-size: 30px; }
        .purchase .grid-row .item .des h5 {
          color: #1D222C;
          text-transform: uppercase;
          font-weight: 900; }
      .purchase .grid-row .item .cta-container {
        margin-top: auto;
        width: 100%; }

@media only screen and (max-width: 767px) {
  .purchase .grid-row {
    grid-template-columns: 1fr;
    gap: 50px;
    max-width: 500px;
    margin: 0 auto; }
  .purchase .grid-row .item .text-style {
    margin: 10px 0; } }

@media only screen and (max-width: 600px) {
  .purchase {
    background-attachment: scroll;
    background-position: bottom center; } }

/**********************
about
**********************/
.about {
  text-align: center;
  color: #1D222C; }
  .about .container {
    position: relative;
    z-index: 1; }
  .about .grid-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px; }
    .about .grid-row .item {
      background-color: #fff;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: start;
      justify-content: flex-start;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column; }
      .about .grid-row .item .img-container {
        margin: 0 0 20px; }
      .about .grid-row .item .price {
        font-weight: 900;
        color: #1D222C;
        margin: 16px 0 30px;
        font-size: 30px; }
        .about .grid-row .item .price span {
          text-decoration: line-through;
          opacity: 0.3; }
      .about .grid-row .item .lines {
        max-width: 400px;
        margin: 0 auto; }
        .about .grid-row .item .lines .sub-heading {
          font-weight: 900; }
      .about .grid-row .item .text-style {
        margin: 30px 0; }
      .about .grid-row .item .des {
        padding: 15px 0;
        width: 100%; }
        .about .grid-row .item .des .title {
          font-weight: 900;
          color: #1D222C;
          font-size: 30px; }
        .about .grid-row .item .des h5 {
          color: #1D222C;
          text-transform: uppercase;
          font-weight: 900; }

@media only screen and (max-width: 767px) {
  .about .grid-row {
    grid-template-columns: 1fr;
    max-width: 500px;
    margin: 0 auto; } }

/**********************
reviews
**********************/
.reviews {
  background: #EAAE27;
  font-weight: 900;
  text-align: center; }
  .reviews .section-header {
    margin-bottom: 60px; }
  .reviews .slick-track {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
  .reviews .slick-dotted.slick-slider {
    margin-bottom: 0; }
  .reviews .product-next,
  .reviews .product-prev {
    cursor: pointer; }
  .reviews .quote {
    position: relative;
    font-size: 32px;
    margin-bottom: 40px;
    line-height: 1.2;
    font-weight: 700;
    color: #1D222C; }
    .reviews .quote::before {
      position: absolute;
      top: 0;
      left: -60px;
      content: url("../img/quote-left.png");
      z-index: -1; }
    .reviews .quote::after {
      position: absolute;
      bottom: -37px;
      right: -60px;
      content: url("../img/quote-right.png");
      z-index: -1; }
  .reviews .reviewer {
    font-size: 20px;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    color: #1D222C;
    font-weight: 900;
    text-transform: uppercase; }
    .reviews .reviewer img {
      margin-right: 10px; }

@media only screen and (max-width: 767px) {
  .reviews .quote {
    font-size: 23px;
    margin-bottom: 30px; } }

@media only screen and (max-width: 600px) {
  .reviews .reviewer {
    font-size: 17px; } }

/**********************
FAQ
*************************/
.faq {
  text-align: center;
  font-size: 16px; }
  .faq .section-header {
    margin-bottom: 40px; }
  .faq .cta-container {
    margin-top: 50px; }
  .faq .arrow {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

.rotate {
  -webkit-transform: rotate(0) !important;
  transform: rotate(0) !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

@-webkit-keyframes spin1 {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(180deg); } }

@-keyframes spin1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); } }

.card-header:first-child {
  border-radius: 0; }

.card:last-child .card-header {
  border-bottom: 1px solid #e8e8e8; }

.active-question {
  border-top: 0 !important;
  border-bottom: 0 !important; }

#questions {
  text-align: left; }
  #questions .card {
    border-left: 0;
    border-right: 0; }
    #questions .card:first-child {
      border-top: 0; }
    #questions .card .sub-text:not(:last-child) {
      margin-bottom: 30px; }
    #questions .card h6 {
      color: #1D222C;
      font-weight: 700;
      margin-bottom: 0; }
    #questions .card sup {
      color: #EAAE27;
      font-weight: 700; }
    #questions .card .card-header {
      padding: 0;
      position: relative;
      background-color: #fff;
      border: 0; }
    #questions .card .card-body {
      border-bottom: 0;
      padding: 0 0 20px; }
  #questions .btn-link {
    width: 100%;
    display: inline-block;
    position: relative;
    text-align: left;
    color: #1D222C;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: baseline;
    align-items: baseline;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 12px 12px 12px 0;
    white-space: inherit;
    font-size: 18px;
    font-weight: 900;
    cursor: pointer;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8; }
    #questions .btn-link svg {
      position: absolute;
      top: 50%;
      right: 16px;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%); }

.collapsed.show {
  border-top: 0 !important;
  border-bottom: 0 !important; }

@media only screen and (max-width: 767px) {
  #questions .btn-link {
    font-size: 17px; } }

@media only screen and (max-width: 600px) {
  #questions .btn-link {
    font-size: 16px; } }

.cta {
  background-color: #F2F2F2;
  text-align: center;
  color: #1D222C; }
  .cta .section-header {
    margin-bottom: 0; }
  .cta strong {
    color: #EAAE27;
    font-weight: 700; }
  .cta .price {
    color: #EAAE27;
    font-weight: 700; }
  .cta .cta-container {
    margin-top: 30px; }

/**********************
Footer
**********************/
footer {
  background-color: #1D222C;
  color: #fff;
  padding: 60px 0;
  font-weight: 500; }
  footer .brand {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 100%;
    width: 100%; }
    footer .brand .img-container {
      text-align: left; }
  footer .foot-item {
    font-family: raleway, sans-serif;
    font-weight: 500; }
    footer .foot-item .links {
      display: -webkit-box;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column; }
  footer a {
    color: #fff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
    footer a:hover {
      color: #EAAE27; }
  footer .footer_title {
    text-transform: uppercase;
    color: #EAAE27;
    margin-bottom: 10px;
    font-weight: 900;
    letter-spacing: 1px; }
  footer .social-content {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between; }
  footer .copyright {
    margin-top: auto;
    text-transform: uppercase;
    font-size: 13px; }
  footer .social {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 100%;
    display: -webkit-box;
    display: flex; }
    footer .social svg {
      margin: 0 7px;
      font-size: 18px;
      color: #fff;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      footer .social svg:hover {
        color: #EAAE27; }

@media only screen and (max-width: 1199px) {
  footer {
    text-align: center; }
    footer .branding {
      -webkit-box-ordinal-group: 5;
      order: 4;
      text-align: center; }
  footer .brand .img-container {
    margin: 40px 0 10px;
    text-align: center; } }

@media only screen and (max-width: 767px) {
  .foot-item {
    margin-bottom: 20px; } }

/** MEDIA QUERIES **/
/******************************
Mobile - Landscape
******************************/
